<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      Создать Опрос
    </VCardTitle>

    <!-- FIXME: временно грязное решение -->
    <!-- eslint-disable vue/no-mutating-props -->
    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Имя опроса"
                placeholder="lorem ipsum"
                name="surveyName"
                hint="Может быть пустым, тогда бэкенд сам сгенерирует"
                persistent-hint
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.type"
                :items="types"
                item-text="title"
                item-value="value"
                label="Тип"
                @input="$emit('update:type', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.companyId"
                :items="companies"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                label="Компания"
                @input="$emit('update:companyId', $event)"
                @keydown.enter="handleSubmit"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.teamUids"
                :items="teams"
                :disabled="entity.companyId === null"
                multiple
                item-text="name"
                item-value="id"
                label="Команды"
                @input="$emit('update:teamUids', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VCheckbox
                v-model="entity.welcomePageEnabled"
                label="Страница приветствия"
                @change="$emit('update:welcomePageEnabled', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.welcomePageTitle"
                label="Заголовок страницы приветствия"
                name="surveyWelcomePageTitle"
                hint="Может быть пустым, тогда бэкенд сам сгенерирует"
                persistent-hint
                @input="$emit('update:welcomePageTitle', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :value="entity.welcomePageBody"
                label="Текст страницы приветствия"
                name="surveyWelcomePageBody"
                hint="Может быть пустым, тогда бэкенд сам сгенерирует"
                persistent-hint
                @input="$emit('update:welcomePageBody', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VCheckbox
                v-model="entity.finishPageEnabled"
                label="Страница завершения"
                @change="$emit('update:finishPageEnabled', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.finishPageTitle"
                label="Заголовок страницы завершения"
                name="surveyFinishPageTitle"
                hint="Может быть пустым, тогда бэкенд сам сгенерирует"
                persistent-hint
                @input="$emit('update:finishPageTitle', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :value="entity.finishPageBody"
                label="Текст страницы завершения"
                name="surveyFinishPageBody"
                hint="Может быть пустым, тогда бэкенд сам сгенерирует"
                persistent-hint
                @input="$emit('update:finishPageBody', $event)"
              />
            </VCol>
          </VRow>

          <!-- TODO: убрать мутации v-model -->
          <template v-if="entity.type === 'TYPE_SINGLE'">
            <VRow align="center">
              <VCol cols="auto">
                1
              </VCol>

              <VCol>
                <VTextField
                  v-model="entity.startTimestamps[0]"
                  label="Дата начала"
                />
              </VCol>

              <VCol>
                <VTextField
                  v-model="entity.endTimestamps[0]"
                  label="Дата конца"
                />
              </VCol>
            </VRow>
          </template>

          <!-- TODO: убрать мутации v-model -->
          <template v-else-if="entity.type === 'TYPE_MULTIPLE'">
            <VRow
              v-for="(date, index) in entity.startTimestamps"
              :key="index"
              align="center"
            >
              <VCol cols="auto">
                {{ index + 1 }}
              </VCol>

              <VCol>
                <VTextField
                  v-model="entity.startTimestamps[index]"
                  label="Дата начала"
                />
              </VCol>

              <VCol>
                <VTextField
                  v-model="entity.endTimestamps[index]"
                  label="Дата конца"
                />
              </VCol>
            </VRow>
          </template>
        </VContainer>
      </VForm>
    </VCardText>
    <!-- eslint-enable vue/no-mutating-props -->

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        Создать
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'SurveyForm',

  props: {
    entity: {
      type: Object,
      default: () => ({
        name: null,
        welcomePageEnabled: true,
        welcomePageTitle: null,
        welcomePageBody: null,
        finishPageEnabled: true,
        finishPageTitle: null,
        finishPageBody: null,
        companyId: null,
        teamUids: [],
        type: 'single',
        startTimestamps: [null, null, null, null],
        endTimestamps: [null, null, null, null],
      }),
    },

    companies: {
      type: Array,
      required: true,
    },

    teams: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      types: [
        { title: 'single', value: 'TYPE_SINGLE' },
        { title: 'multiple', value: 'TYPE_MULTIPLE' },
      ],
    };
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
