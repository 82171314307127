<template>
  <TTView>
    <VRow>
      <VCol>
        <VAlert
          colored-border
          border="left"
          type="info"
          elevation="1"
        >
          Даты нужно указывать с <strong>timezone</strong> ручками в формате ISO. <br>
          Пример: <code>2020-11-17T07:06:09.098Z</code>
        </VAlert>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <VAlert
          colored-border
          border="left"
          type="info"
          elevation="1"
        >
          Если тип опроса <strong>multiple</strong> обязательно нужно указать все 4 даты.
        </VAlert>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <SurveyForm
          :entity="survey"
          :companies="companies"
          :teams="teams"
          :loading="loading"
          @update:name="survey.name = $event"
          @update:type="survey.type = $event"
          @update:companyId="survey.companyId = $event"
          @update:teamUids="survey.teamUids = $event"
          @update:welcomePageEnabled="survey.welcomePageEnabled = $event"
          @update:welcomePageTitle="survey.welcomePageTitle = $event"
          @update:welcomePageBody="survey.welcomePageBody = $event"
          @update:finishPageEnabled="survey.finishPageEnabled = $event"
          @update:finishPageTitle="survey.finishPageTitle = $event"
          @update:finishPageBody="survey.finishPageBody = $event"
          @submit="handleSubmit"
          @cancel="handleCancel"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import SurveyForm from '../components/SurveyForm.vue';

export default {
  name: 'SurveysCreate',

  components: {
    SurveyForm,
  },

  inject: ['Names'],

  data() {
    return {
      companies: [],
      teams: [],
      loading: false,
      survey: {
        name: null,
        welcomePageEnabled: true,
        welcomePageTitle: null,
        welcomePageBody: null,
        finishPageEnabled: true,
        finishPageTitle: null,
        finishPageBody: null,
        companyId: null,
        teamUids: [],
        type: 'TYPE_SINGLE',
        startTimestamps: [null, null, null, null],
        endTimestamps: [null, null, null, null],
      },
    };
  },

  watch: {
    'survey.type': {
      handler() {
        this.survey.startTimestamps = [null, null, null, null];
        this.survey.endTimestamps = [null, null, null, null];
      },
    },

    'survey.companyId': {
      async handler(companyId) {
        this.survey.teamUids = [];

        try {
          this.loading = true;

          const teamIndexResponse = await this.$di.api.Orgstructure.teamIndex(companyId);

          this.teams = teamIndexResponse.teams || [];
        } catch (err) {
          this.$di.notify.errorHandler(err);
        } finally {
          this.loading = false;
        }
      },
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const [companiesResponse] = await Promise.all([this.$di.api.Account.indexCompany()]);

        this.companies = companiesResponse.companies || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const { survey } = this;
        const startTimestamps = survey.startTimestamps.filter(Boolean);
        const endTimestamps = survey.endTimestamps.filter(Boolean);

        await this.$di.api.Engagement.surveysCreate({ ...survey, startTimestamps, endTimestamps });

        this.$di.notify.snackSuccess('Опрос создан');

        this.$router.push({ name: this.Names.R_ENGAGEMENT_SURVEYS });
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
